import React, { Component } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
import api from '../../../helpers/api';
import Loading from '../../components/Loading';
import url from '../../../helpers/urlFriendly';
import Button from '../../components/Button';

const HighlightsComponent = styled.article`

   text-align: center;
   position: relative;
   padding: 15px 0 0 0;
   overflow: hidden;
   transition: all  0.4s ease-in-out;

	.block{
		background: #000;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
	}

   a{
       color: #fff !important;
   }

   .main{
	 height: auto;
	 transition: all  0.4s ease-in-out;
	 display: block;
	 opacity: 1;
   }

	.hidden{
		height: 0px !important;
		opacity: 0;
		transition: all  0.4s ease-in-out;
		display: block;
		overflow: hidden;
	}


	@keyframes example {
		
  0%   {background-color: red;}
  25%  {background-color: yellow;}
  50%  {background-color: blue;}
  100% {background-color: green;}
  
}

	.carousel{
		
		margin: 10px 5px 0 5px;
		border-radius: 100px;
		padding: 2px;
		position: relative;
		width: 40px;
		height: 40px;
		
	}




	.carousel::after {
		content: ".";
		font-size: 60px;
		border-radius: 50px;
		line-height: 0px;
		font-weight: 800;
		}

   h2{
       font-size: 28px;
       margin: 0;
       padding: 10%;
       position: relative;
       z-index: 2;
   }

   a:hover{
	opacity: 0.8;
   }

   @media (max-width: 991px){
		.division{
			margin: 15px 0;
		}
	}

  

`;


const Highlight = styled.div`
   background: url(${props => props.background});
   background-size: cover;
   background-position-y: 43% !important;
   height: 220px;
   width: 100%;
   overflow: hidden;
   position: relative;

   div{
       width: 100%;
       height: 220px;
       background-color: #000;
       opacity: 0.6;
       position: absolute;
   }

   

`;


const LoadingComponent = styled.div`

`;


class Highlights extends Component {
	state = {
		page: 1,
	}

	componentDidMount() {
		const { query, variables, onLoad } = this.props;


		const {
			client,
		} = this.props;

		const request = {
			query,
			variables,
			errorPolicy: 'all',
		};

		const response = api.get(client, request);

		window.scrollTo(0, 0);

		onLoad(response);
	}


	changePage = (page) => {
		this.setState({
			page,
		});
	}


	render() {
		const { page } = this.state;
		const { data, loading } = this.props;
		if (loading) {
			return (<LoadingComponent><Loading on={loading} /></LoadingComponent>);
		}

		const newPage = page === 1 ? 0 : 1;

		setTimeout(() => {
			this.setState({ page: newPage });
		}, 5000);

		return (
			<HighlightsComponent>
				<Grid fluid>
					<Row center="xs">
						<Col
							lg={8}
							sm={12}
							style={{ maxHeight: 200, overflow: 'hidden' }}
						>
							{
								data && data.length !== 0
									? data.map((item, index) => (
										<Link key={item.hqCover} className={'link' && page === index ? 'main' : 'hidden'} to={`/hq/${item.hqId}/${url.urlFriendly(item.name)}`}>
											<Highlight background={item.hqCover}>
												<h2>
													{item.name}
												</h2>
												<div className="block" />
											</Highlight>
										</Link>
									)) : null
							}
						</Col>
						<Col
							lg={4}
							sm={12}
							style={{ maxHeight: 200, overflow: 'hidden' }}
						>
							<div className="division" />
							{
								data && data.length !== 0
									? data.map((item, index) => (
										<Link key={item.hqCover} className={'link' && page + 2 === index ? 'main' : 'hidden'} to={`/hq/${item.hqId}/${url.urlFriendly(item.name)}`}>
											<Highlight background={item.hqCover}>
												<h2>
													{item.name}
												</h2>
												<div className="block" />
											</Highlight>
										</Link>
									)) : null
							}
						</Col>
					</Row>
					<Row center="xs">
						<Col>
							<Button
								styleButton={page === 0 ? 'carousel--select' : 'carousel'}
								onClick={() => { this.changePage(1); }}
								title="Página 1"
								className="carousel"
							>
								{''}
							</Button>
						</Col>
						<Col>
							<Button
								styleButton={page === 1 ? 'carousel--select' : 'carousel'}
								onClick={() => { this.changePage(0); }}
								title="Página 2"
								className="carousel"
							>
								{''}
							</Button>
						</Col>
					</Row>
				</Grid>
			</HighlightsComponent>
		);
	}
}

export default withApollo(withRouter(Highlights));
