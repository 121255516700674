import React, { Component } from 'react';
import styled from 'styled-components';
import Disqus from 'disqus-react';
import { withRouter } from 'react-router';
import { FacebookProvider, Page } from 'react-facebook';
import Figure from '../Figure';


const RenderComponent = styled.ul`
	list-style: none;
	padding: 0;

	.imageRender{
		margin: auto;
		max-width: 1280px;
		width: 90%;
		min-height: 80vh;
	}

	.coments{
		margin: auto;
		max-width: 1280px;
		width: 90%;
		z-index: 100;
		position: relative;
	}
`;


class RenderHQ extends Component {
	state = {
		page: 0,
		data: [],
		loading: true,
	}


	componentDidMount() {
		const { data, page } = this.props;

		const nextPage = data[page - 1] ? page - 1 : page;

		this.setState({
			data,
			page: nextPage,
			loading: false,
		});
	}

	preLoadImages = (data, page) => {
		if (data[page + 1]) {
			const img = new Image();
			img.src = data[page + 1].pictureUrl;
		}

		if (data[page + 2]) {
			const img = new Image();
			img.src = data[page + 2].pictureUrl;
		}
	}


	render() {
		const { data, page, loading } = this.state;

		const { fullScreen } = this.props;

		const disqusShortName = process.env.REACT_APP_DISQUS_SHORTNAME;

		const { location, match } = this.props;

		const { params } = match;

		const disqusConfig = {
			url: location.href,
			identifier: location.pathname,
			title: `${params.title} ${params.number}`,
		};

		if (loading) {
			return <div />;
		}

		return (
			<RenderComponent>
				{
					data && data[page]
						? (
							<div className="imageRender" key={data[page].pictureUrl}>
								<Figure
									imageUrl={data[page].pictureUrl}
									alt={page}
									fullScreen={fullScreen}
									width="auto"
								/>
								{
									this.preLoadImages(data, page)
								}
							</div>
						)

						: (
							<div>
								<FacebookProvider appId="617247364982234">
									<Page href="https://www.facebook.com/HqNow-250284552546512" tabs="timeline" />
								</FacebookProvider>
								<div className="coments">
									<Disqus.DiscussionEmbed shortname={disqusShortName} config={disqusConfig} />
								</div>
							</div>
						)
				}

			</RenderComponent>
		);
	}
}


export default withRouter(RenderHQ);
