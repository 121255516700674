import Home from '../app/pages/home';
import HQ from '../app/pages/hq';
import Search from '../app/pages/search';
import HQReader from '../app/pages/hqReader';
import Publisher from '../app/pages/publisher';
import MostView from '../app/pages/mostview';

export default [
	{
		path: '/',
		exact: true,
		component: Home,
		title: 'Home',
		menu: true,
	},
	{
		path: '/search',
		exact: true,
		component: Search,
		title: 'Buscar',
		menu: true,
	},
	{
		path: '/hq/:id/:title',
		exact: false,
		component: HQ,
		title: 'HQ',
		menu: false,
	},
	{
		path: '/publisher/:id/:title',
		exact: false,
		component: Publisher,
		title: 'Editoras',
		menu: false,
	},
	{
		path: '/hq-reader/:id/:title/chapter/:number/page/:pageNumber',
		exact: true,
		component: HQReader,
		title: 'Reader',
		menu: false,
	},
	{
		path: '/mostview',
		exact: true,
		component: MostView,
		title: 'Mais Vistos',
		menu: true,
	},

];
