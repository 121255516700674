import React, { Component } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import api from '../../../helpers/api';
import Loading from '../../components/Loading';
import ListFigures from '../../components/ListFigures';

const ListFiguresHQComponent = styled.article`

   text-align: center;
   
   h2{
       font-size: 14px;
   }

`;


const LoadingComponent = styled.div`

`;


class ListFiguresHQ extends Component {
	componentDidMount() {
		const { query, variables, onLoad } = this.props;


		const {
			client,
		} = this.props;

		const request = {
			query,
			variables,
			errorPolicy: 'all',
		};

		const response = api.get(client, request);

		window.scrollTo(0, 0);

		onLoad(response);
	}


	render() {
		const { data, loading, horizontal } = this.props;

		if (loading) {
			return (<LoadingComponent><Loading on={loading} /></LoadingComponent>);
		}

		return (
			<ListFiguresHQComponent>
				<ListFigures data={data} id="id" name="name" imageUrl="hqCover" loading={loading} horizontal={horizontal} />
			</ListFiguresHQComponent>
		);
	}
}

export default withApollo(withRouter(ListFiguresHQ));
