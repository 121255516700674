import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';

const ChangeChapterForm = styled.div`
  padding: 2px;
  overflow: hidden;
  padding: 0 10px;
  width: auto;
  position: relative;
  z-index: 100;
  text-align: center;

  button{
	  width: auto;
	  display: inline-block;
	  text-transform: capitalize;
	  padding: 14px;
	  border: 1px solid #555;
	  border-radius: 30px;
	  background-color: #fff;
	  margin: 10px;
  }

`;

class ChangeChapter extends Component {
	changeChapter = (chapter) => {
		const { onChangeChapter } = this.props;

		onChangeChapter(chapter);
	}

	render() {
		const { nextChapter, previousChapter } = this.props;


		return (
			<React.Fragment>

				{
					previousChapter || nextChapter ? (
						<ChangeChapterForm>
							{
								previousChapter
					&& (
						<Button
							onClick={() => this.changeChapter(previousChapter)}
							title="Próximo Capítulo"
							styleButton="light"
						>
							{'Capítulo Anterior'}
						</Button>
					)
							}
							{
								nextChapter
					&& (
						<Button
							onClick={() => this.changeChapter(nextChapter)}
							title="Próximo Capítulo"
							styleButton="light"
						>
							{'Próximo Capítulo'}
						</Button>
					)
							}
						</ChangeChapterForm>
					) : <div />
				}

			</React.Fragment>
		);
	}
}


export default ChangeChapter;
