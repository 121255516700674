import React, { Component } from 'react';
import propTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import LogoIcon from '../../../assets/images/logo-hq-now.svg';
import MenuOpenIcon from '../../../assets/images/menu-open.svg';
import MenuClosedIcon from '../../../assets/images/menu-closed.svg';

const HeaderMenu = styled.header`
  background-color: ${props => props.style.background.color};
  height: ${props => props.style.background.height};
  border-bottom: 1px solid #dddddd;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: all .6s ease-in-out;
  width: initial;
  z-index: 1001;
  padding: 3px 0;



  @media (max-width: 767px){
	&{
		height: 100px;
		padding-bottom: 10px;
	}
  }


  .link{
		text-decoration: none
  }
  
  .logo{
	color: ${props => props.style.font.color};
	font-family: ${props => props.style.font.family};
	font-size: ${props => props.style.font.size};
	font-weight: 500;
	margin: 0px;
	padding-top: 5px;

	img{
		width: 140px;
	}

  }

  > div{
	  height: 100%;
  }

  > div > div{
	  height: 100%;
  }
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  padding: 10px;
  cursor: pointer;
  float: right;
`;

const Both = styled.div`
	position: relative;
	height: ${props => props.style.background.height};
	z-index: -1;
	
	@media (max-width: 767px){
		&{
			height: 100px;
			padding-bottom: 10px;
		}
	}


`;

const Menu = styled.nav`
	background-color: ${props => props.style.background.color};
	font-size: ${props => props.style.font.size};
	color: ${props => props.style.font.color};
	border-right: 1px #ccc solid;
	display: block;
	height: 100vh;
	padding: 0 12px;
	position: fixed;
	right:  ${props => (props.open ? '0px' : '-110%')};
	min-width: 200px;
	z-index: 1000;
	transition: all  0.5s ease-in-out;
  



  ul{
	padding: 0px;
  }

  ul li{
	list-style: none;
	padding: 20px 0;
	text-align: left;
  }

  .link{
	text-decoration: none;
	width: 100%;
	display: block;
	padding: 6px 10px;
	color: ${props => props.style.font.color};
	transition: color 300ms ease-in-out;
	border-left: ${props => props.style.font.colorHover}  2px solid;
  }

  .link:hover{
	text-decoration: none;
	color: ${props => props.style.font.colorHover};
	border-left: ${props => props.style.font.color} 2px solid;
  }

  @media (max-width: 620px){
	&{
		width: 100vw;
	}

	ul li{
	   padding:  20px 0;
	   font-size: 16px;
	}
  }

`;

const Overlay = styled.div`
  background-color: #444;
  opacity: ${props => (props.open ? '0.3' : '0')};
  z-index: ${props => (props.open ? '999' : '-999')};
  position: fixed;
  width: 100%;
  height: 100%;
  transition: all  300ms ease-out;
`;


class Header extends Component {
	state = {
		open: false,
	}

	onClickHandler = () => {
		const { open } = this.state;
		this.setState({
			open: !open,
		});
	}


	renameTitle() {
		const { pages, title } = this.props;

		const currentPage = pages.filter(page => this.getLocationPage(page));

		document.title = `${title} - ${currentPage.length !== 0 ? currentPage[0].title : ''}`;
	}

	render() {
		const {
			pages, theme, title, children,
		} = this.props;

		const { open } = this.state;

		return (
			<header>
				<HeaderMenu style={theme.header}>
					<Grid fluid>
						<Row middle="xs">
							<Col lg={7} md={7} sm={12}>
								<Row middle="xs" start="md" center="xs">
									<Col>
										<Link className="link" to="/">
											<h1 className="logo">
												<img src={LogoIcon} alt={title} title={title} />
											</h1>
										</Link>
									</Col>
								</Row>
							</Col>
							<Col lg={5} md={5} sm={12}>
								<Row middle="xs" end="xs">
									<Col xs={10}>
										{children}
									</Col>
									<Col>
										{
											open ? <Icon src={MenuClosedIcon} title="abrir" alt="abrir" onClick={this.onClickHandler} />
												: <Icon src={MenuOpenIcon} title="fechar" alt="fechar" onClick={this.onClickHandler} />

										}
									</Col>
								</Row>
							</Col>
						</Row>
					</Grid>
				</HeaderMenu>
				<Both style={theme.header} className="reset" />
				<Menu style={theme.menu} open={open}>
					<ul>
						{
							pages.map((page) => {
								if (page.menu) {
									return (
										<li key={page.path}>
											<Link className="link" to={page.path} onClick={this.onClickHandler}>{page.title}</Link>
										</li>
									);
								}
								return null;
							})
						}
					</ul>
				</Menu>
				<Overlay open={open} />
			</header>
		);
	}
}

Header.propTypes = {
	title: propTypes.string,
	theme: propTypes.instanceOf(Object).isRequired,
	pages: propTypes.instanceOf(Array).isRequired,
};

Header.defaultProps = {
	title: 'Boilerplate',
};

export default withRouter(withTheme(Header));
