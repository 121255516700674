import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withRouter } from 'react-router';
import Search from '../../containers/Search';
import actions from './store/actions';
import getHqsByName from '../../graphql/query/getHqsByName';
import Header from '../../components/Header';

class MainHeader extends Component {
	state = {
		error: false,
	}

	onSearch = async (responseSearch) => {
		const { dispatch, history } = this.props;
		dispatch(actions.searchListHQ(responseSearch));
		history.push('/search');
	}

	componentDidCatch() {
		this.setState({
			error: true,
		});
	}


	render() {
		const { error } = this.state;

		const {
			title, pages,
		} = this.props;


		return (

			<Header pages={pages} title={title}>
				{error
					? (
						<div>
							{' Ocorreu um erro.'}
						</div>
					)
					: 					(
						<Search
							onSearch={this.onSearch}
							parameter="name"
							query={getHqsByName}
							textPlaceholder="Buscar por..."
						/>
					)
				}
			</Header>

		);
	}
}

const mapStateToProps = state => ({
	hqs: state.hqs,
});

MainHeader.propTypes = {
	dispatch: propTypes.func.isRequired,
};


export default connect(mapStateToProps)(withRouter(MainHeader));
