import React, { Component } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { Grid, Row, Col } from 'react-flexbox-grid';
import api from '../../../helpers/api';
import Button from '../../components/Button';
import getHqsByNameStartingLetter from '../../graphql/query/getHqsByNameStartingLetter';

const FilterLetterForm = styled.div`
  padding: 2px;
  border: 1px solid #555;
  border-radius: 30px;
  background-color: #fff;
  overflow: hidden;
  padding: 0 10px;
  
  button{
	  width: auto;
	  display: inline-block;
	  text-transform: capitalize;
	  padding: 14px;
  }

`;

class FilterLetter extends Component {
	state = {
		alphabets: [],
	}

	componentDidMount() {
		this.setState({
			alphabets: this.listAlphabet(),
		}, () => {
			// eslint-disable-next-line react/destructuring-assignment
			const { fromSearch } = this.props.hqs;
			if (!fromSearch) {
				this.onFilterLetter('a');
			}
		});
	}


	onFilterLetter = (letter) => {
		const {
			onFilterLetter, client,
		} = this.props;


		const request = {
			query: getHqsByNameStartingLetter,
			variables: {
				letter: letter === '0-9' ? `${'0'}-${'9'}` : `${letter}-${letter}`,
			},
			errorPolicy: 'all',
		};
		const response = api.get(client, request);

		window.scrollTo(0, 0);

		onFilterLetter(response);
	}

	listAlphabet = () => {
		const alphabets = [];
		const start = 'a'.charCodeAt(0);
		const last = 'z'.charCodeAt(0);
		for (let i = start; i <= last; i += 1) {
			alphabets.push(String.fromCharCode(i));
		}

		alphabets.unshift('0-9');

		return alphabets;
	}

	render() {
		const { alphabets } = this.state;

		return (
			<Grid>
				<Row center="xs">
					<Col>
						<FilterLetterForm>
							{

								alphabets.map(letter => (

									<Button type="submit" styleButton="light" onClick={() => { this.onFilterLetter(letter); }}>
										{letter}
									</Button>

								))
							}
						</FilterLetterForm>
					</Col>
				</Row>
			</Grid>
		);
	}
}


export default withApollo(FilterLetter);
