
import type from './action-type';

const searchPublisher = response => async (dispatch) => {
	try {
		const responseAPI = await response;
		dispatch({
			type: type.SEARCH_PUBLISHER,
			loading: false,
			hqs: responseAPI,
			error: null,
			name: responseAPI[0].publisherName,
			id: responseAPI[0].editoraId,
		});
	} catch (error) {
		dispatch({
			type: type.SEARCH_PUBLISHER,
			loading: false,
			hqs: [],
			error,
			name: '',
			id: null,
		});
	}
};

export default { searchPublisher };
