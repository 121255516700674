import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import ListHQ from '../../modules/ListHQ';

const SearchPage = styled.div`
	  min-height: 100vh;
	  padding-top: 20px;
	  padding-bottom: 20px;	  
`;


const Search = () => (
	<SearchPage>
		<MetaTags>
			<title>HQ Now - Buscar</title>
			<meta name="description" content="HQ Now" />
			<meta property="og:title" content="HQ Now" />
		</MetaTags>
		<Grid fluid>
			<Row center="xs">
				<Col xs={12} style={{ maxWidth: 1280 }}>
					<ListHQ />
				</Col>
			</Row>
		</Grid>
	</SearchPage>
);

export default Search;
