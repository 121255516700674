
import type from './action-type';

const searchListHQ = search => async (dispatch) => {
	dispatch({
		type: type.SEARCH_HQ,
		loading: true,
		hqs: [],
		error: null,
	});

	search.then((response) => {
		dispatch({
			type: type.SEARCH_HQ,
			loading: false,
			hqs: response,
			error: null,
		});
	})
		.catch((error) => {
			dispatch({
				type: type.SEARCH_HQ,
				loading: false,
				hqs: [],
				error,
			});
		});
};

export default { searchListHQ };
