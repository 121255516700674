import { combineReducers } from 'redux';
import hqsReducer from '../app/modules/ListHQ/store/reducer';
import hqReducer from '../app/modules/CoverHQ/store/reducer';
import hqChapterReducer from '../app/modules/RenderHQ/store/reducer';
import mostViewReducer from '../app/modules/MostView/store/reducer';
import publisherReducer from '../app/modules/ListPublisher/store/reducer';
import lastUpdatedReducer from '../app/modules/LastUpdated/store/reducer';
import HighlightsReducer from '../app/modules/Highlights/store/reducer';

export default combineReducers({
	hqs: hqsReducer,
	hq: hqReducer,
	hqChapter: hqChapterReducer,
	mostView: mostViewReducer,
	publisher: publisherReducer,
	lastUpdated: lastUpdatedReducer,
	highlights: HighlightsReducer,
});
