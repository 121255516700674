import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import actions from './store/actions';
import ListFiguresHQ from '../../containers/ListFiguresHQ';
import getHqsByFilters from '../../graphql/query/getHqsByFilters';

const MostViewModule = styled.section`

  min-height: 320px;

  a{
	text-decoration: none;
	color: ${props => props.style.font.colorHover};
	transition: color 300ms ease-in-out;
  }

  a:hover{
	text-decoration: none;
	color: ${props => props.style.font.color};
  }
  
`;

class CoverHQ extends Component {
	state = {
		error: false,
		id: null,
	}


	componentDidMount() {
		const { id } = this.state;

		const { match } = this.props;

		document.top = 0;

		if (match && id === null) {
			const { params } = match;

			this.setState({
				id: parseInt(params.id, 10),
			});
		}
	}


	onLoad = async (chapter) => {
		const { dispatch } = this.props;
		dispatch(actions.mostView(chapter));
	}


	componentDidCatch() {
		this.setState({
			error: true,
		});
	}


	render() {
		const {
			error,
		} = this.state;

		const {
			theme,
			mostView,
			limit,
			pageMostView,
		} = this.props;


		const {
			loading,
			list,
		} = mostView;


		if (error) {
			return (<div> Ocorreu um Erro </div>);
		}


		return (


			<MostViewModule style={theme.menu}>
				<h2 className="title">Mais Vistos</h2>
				<ListFiguresHQ
					query={getHqsByFilters}
					variables={{
						orderByViews: true,
						loadCovers: true,
						limit,
					}}
					data={list}
					onLoad={this.onLoad}
					loading={loading}
				/>
				{
					pageMostView
						? <>
							<hr />
							<Link className="link" to="/mostview">Ver Mais</Link>
					</> : <></>
				}

			</MostViewModule>

		);
	}
}

const mapStateToProps = state => ({
	mostView: state.mostView,
});


export default connect(mapStateToProps)(withRouter(withTheme(CoverHQ)));
