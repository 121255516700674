
import gql from 'graphql-tag';

export default gql`
query getHqsByPublisherId(
  $publisherId: Int
) {
    getHqsByFilters (
        publisherId: $publisherId
    ){
        id
        name 
        editoraId
        status
        publisherName
  }
}`;
