import React, { Component } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import MetaTags from 'react-meta-tags';
import { withRouter } from 'react-router';
import Fullscreen from 'react-full-screen';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { Link } from 'react-router-dom';
import getChapterById from '../../graphql/query/getChapterById';
import api from '../../../helpers/api';
import Loading from '../../components/Loading';
import Button from '../../components/Button';


const PaginationComponent = styled.article`

   text-align: center;
   
   h2{
       font-size: 14px;
   }

`;

const PaginationCursor = styled.div`

   	position: relative;
	display: block;
	background-color: red;
	
	button{
		width: auto;
		display: inline-block;
		padding: 20px;
		font-size: 18px;
	}

	.next{
		position: fixed;
		right: 0px;
		top: 0;
		width:50%;
		height: 100%;
		opacity: 0;
	}

	.previous{
		position: fixed;
		left: 0px;
		top: 0;
		width:50%;
		height: 100%;
		opacity: 0;
	}

`;

const PaginationNumber = styled.div`

  padding: 2px;
  border: 1px solid #555;
  border-radius: 30px;
  background-color: #fff;
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  padding: 0 10px;
  position: relative;
  z-index:10;
  
  button{
	  width: auto;
	  display: inline-block;
	  text-transform: capitalize;
	  padding: 14px;
  }

`;

const LoadingComponent = styled.div`

`;


class Pagination extends Component {
	state = {
		id: null,
		loading: true,
		chapter: null,
		page: null,
		chapterReponse: null,
		loadingRender: false,
		isFull: false,
	}

	componentWillReceiveProps(props) {
		const { chapterId, page, chapter } = props;

		const { id } = this.state;

		const {
			onLoadChapter, client,
		} = this.props;

		if (chapterId !== id) {
			this.setState({
				id: chapterId,
				page,
				chapter,
			}, async () => {
				const request = {
					query: getChapterById,
					variables: {
						chapterId,
					},
					errorPolicy: 'all',
				};


				const response = await api.get(client, request);

				this.setState({
					chapterReponse: response,
					loading: false,
				}, () => {
					const { chapterReponse } = this.state;

					window.scrollTo(0, 0);

					onLoadChapter(chapterReponse);
				});
			});
		}
	}

	changePage = (numberPage) => {
		const { match, history, pictures } = this.props;

		if (numberPage > 0 && numberPage <= (pictures.length + 1)) {
			this.setState({
				page: numberPage,
				loadingRender: true,
			}, () => {
				const { id, title, number } = match.params;
				this.setState({
					loadingRender: false,
				}, () => {
					history.push(`/hq-reader/${id}/${title}/chapter/${number}/page/${numberPage}`);
					window.scrollTo(0, 0);
				});
			});
		}
	}

	goFull = () => {
		this.setState({ isFull: true });
	}

	previousChapter = () => {
		this.setState({
			page: 1,
			loadingRender: true,
		}, () => {
			const { match, history, previousChapter } = this.props;
			const { title, number } = match.params;
			this.setState({
				loadingRender: false,
			}, () => {
				history.push(`/hq-reader/${previousChapter}/${title}/chapter/${number}/page/1`);
			});
		});
	}

	nextChapter = () => {
		this.setState({
			page: 1,
			loadingRender: true,
		}, () => {
			const { match, history, nextChapter } = this.props;
			const { title, number } = match.params;
			this.setState({
				loadingRender: false,
			}, () => {
				history.push(`/hq-reader/${nextChapter}/${title}/chapter/${number}/page/1`);
			});
		});
	}


	render() {
		const {
			chapterReponse, loading, chapter, page, loadingRender, isFull,
		} = this.state;

		const {
			children,
			pictures,
			hq,
		} = this.props;

		if (loading) {
			return (<LoadingComponent><Loading on={loading} /></LoadingComponent>);
		}

		return (


			<PaginationComponent>

				<KeyboardEventHandler
					handleKeys={['right', 'left']}
					handleFocusableElements
					onKeyEvent={(key) => {
						if (key === 'left') {
							this.changePage(page - 1);
						}

						if (key === 'right') {
							this.changePage(page + 1);
						}
					}}
				/>

				<section>
					<h2 className="title title--center">
						<Link to={`/hq/${hq && hq.id}/${hq && hq.name}`}>{hq && hq.name}</Link>
						{' -  Capítulo '}
						{chapter}
						{
							page <= pictures.length ? ` - Página  ${page}` : ' - Comentários'
						}
					</h2>
				</section>

				<Fullscreen
					enabled={isFull}
					onChange={action => this.setState({ isFull: action })}
				>
					<PaginationCursor>
						<Button
							className="previous"
							disabled={page - 1 === 0}
							onClick={() => { this.changePage(page - 1); }}
							title="Anterior"
							type="submit"
						>
							{'<'}
						</Button>

						<Button
							onClick={() => { this.changePage(page + 1); }}
							className="next"
							title="Próximo"
							disabled={page > pictures.length}

							type="submit"
						>
							{'>'}
						</Button>


					</PaginationCursor>


					<Loading on={!pictures || pictures.length === 0 || loadingRender}>
						{React.cloneElement(children, { data: pictures, page, fullScreen: isFull })}
					</Loading>

				</Fullscreen>

				<PaginationNumber>


					<Button
						onClick={this.goFull}
						title="Full Screen"
						styleButton="light"
					>

						{'Full Screen'}

					</Button>
					{
						pictures && pictures.length !== 0
							? pictures.map((item, i) => (
								<Button
									key={item.pictureUrl}
									styleButton={page === i + 1 ? 'light--select' : 'light'}
									onClick={() => { this.changePage(i + 1); }}
									title={`Página ${i + 1}`}
								>
									{i + 1}
								</Button>
							)) : null


					}

					<Button
						styleButton={page > pictures.length ? 'light--select' : 'light'}
						onClick={() => { this.changePage(pictures.length + 1); }}
						title="Comentários"
					>
						{'Comentários'}
					</Button>


				</PaginationNumber>


				<MetaTags>
					<title>
						{`HQ Now - ${hq && hq.name} - Capítulo ${chapter} ${
							page <= pictures.length ? ` - Página  ${page}` : ' - Comentários'
						}`}
					</title>
					<meta name="description" content={chapterReponse.name} />
					<meta property="og:title" content="HQ Now" />
					<meta property="og:image" content={chapterReponse.name} />
				</MetaTags>

			</PaginationComponent>


		);
	}
}

export default withApollo(withRouter(Pagination));
