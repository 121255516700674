
import type from './action-type';

const loadHQ = hq => async (dispatch) => {
	dispatch({
		type: type.LOAD_HQ,
		hq,
	});
};

export default { loadHQ };
