import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import propTypes from 'prop-types';

const ButtonDefault = styled.button`
	background-color:  ${props => (props.styleButton.background)};
	opacity:  ${props => (props.disabled ? 0.5 : 1)};
    color: ${props => (props.styleButton.color)};
    font-size: 1em;
    padding: 0.5em  0.5em;
    border: 1px solid  ${props => (props.styleButton.background)};
	width: 100%;
	transition: all  0.2s ease-in-out;
	outline: none;
	cursor: ${props => (props.disabled ? 'normal' : 'pointer')};

	&:hover{
		transition: all  0.2s ease-in-out;
		background-color:  ${props => (props.styleButton.backgroundHover)};
		border: 1px solid  ${props => (props.styleButton.backgroundHover)};
		color: ${props => (props.styleButton.colorHover)};
	}
`;


class Button extends Component {
	onClickHandler = (event) => {
		const { onClick } = this.props;
		onClick(event);
	}

	onStyleButton = (type) => {
		const { theme } = this.props;

		switch (type) {
			case 'primary':
				return {
					background: theme.colors.main,
					color: theme.colors.alternative,
					backgroundHover: theme.colors.alternative,
					colorHover: theme.colors.main,
				};
			case 'alternative':
				return {
					background: theme.colors.main,
					color: theme.colors.alternative,
					backgroundHover: theme.colors.alternative,
					colorHover: theme.colors.main,
				};
			case 'light':
				return {
					background: '#fff',
					color: theme.colors.alternative,
					backgroundHover: '#fff',
					colorHover: theme.colors.main,
					width: 'auto',
				};
			case 'light--select':
				return {
					background: '#fff',
					color: theme.colors.main,
					backgroundHover: '#fff',
					colorHover: theme.colors.alternative,
					width: 'auto',
				};
			case 'carousel':
				return {
					background: theme.colors.background,
					color: theme.colors.main,
					backgroundHover: '#fff',
					colorHover: theme.colors.main,
					width: 'auto',
				};
			case 'carousel--select':
				return {
					background: theme.colors.background,
					color: theme.colors.alternative,
					backgroundHover: '#fff',
					colorHover: theme.colors.main,
					width: 'auto',
				};
			default:
				return {
					background: theme.colors.main,
					color: theme.colors.alternative,
					backgroundHover: theme.colors.alternative,
					colorHover: theme.colors.main,
				};
		}
	}

	render() {
		const {
			children, styleButton, type, theme, className, disabled,
		} = this.props;


		return (
			<ButtonDefault
				type={type}
				onClick={this.onClickHandler}
				styleButton={this.onStyleButton(styleButton)}
				theme={theme.colors}
				className={className}
				disabled={disabled}
			>
				{children}
			</ButtonDefault>
		);
	}
}

Button.propTypes = {
	onClick: propTypes.func.isRequired,
	styleButton: propTypes.string,
	type: propTypes.string,
};

Button.defaultProps = {
	styleButton: 'primary',
	type: 'button',
};

export default withTheme(Button);
