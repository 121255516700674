import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import NotFoundIcon from '../../../assets/images/not-found.svg';

const TableComponent = styled.table`
	padding: 0;
    border: 0px solid #fff;
    width: 100%;

	
`;

const NotFound = styled.div`
	padding-top: 1%;
`;


const HeaderTable = styled.tr`
    border: 0px solid #fff;

    th{
        font-weight: 600;
        text-align: left;
        padding: 10px 1px;
        border-bottom: 1px solid #ddd;
    }
`;

const Row = styled.tr`

    td{
        text-align: left;
        padding: 10px 1px;
        border-bottom: 1px solid #ddd;
    }
`;


const Table = ({
	data, header, id,
}) => (


	data && data.length !== 0
		? (
			<TableComponent>

				<thead>
					<HeaderTable>
						{
							header.map(cellHeader => (
								<th key={`cell_head_${cellHeader[id]}`}>
									{
										cellHeader.text
									}
								</th>
							))
						}
					</HeaderTable>
				</thead>

				<tbody>
					{
						data.map(row => (
							<Row key={`row_${row[id]}`}>
								{Object.keys(row).map((cellData) => {
									if (cellData !== id) {
										return (
											<td key={`cell_${cellData}_${row[id]}`}>
												{ row[cellData] }
											</td>
										);
									}

									return null;
								})}
							</Row>
						))
					}
				</tbody>

			</TableComponent>
		)
		: (
			<NotFound>
				<div>
					<img className="notFound" src={NotFoundIcon} alt="!" title="Nenhum item encontrado" />
				</div>
				{' Nenhum item encontrado. '}
			</NotFound>
		)
);

Table.propTypes = {
	header: propTypes.instanceOf(Array).isRequired,
	id: propTypes.string.isRequired,
	data: propTypes.instanceOf(Array).isRequired,
};

export default Table;
