
import gql from 'graphql-tag';

export default gql`
query getHqsByNameStartingLetter(
  $letter: String!
) {
    getHqsByNameStartingLetter (
        letter: $letter
    ){
        id
        name 
        editoraId
        status
        publisherName
        impressionsCount
  }
}`;
