import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import { Link } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import HRNumbers from 'human-readable-numbers';
import getHqsById from '../../graphql/query/getHqsById';
import api from '../../../helpers/api';
import Figure from '../../components/Figure';
import Loading from '../../components/Loading';
import url from '../../../helpers/urlFriendly';

const Synopsis = styled.article`

   min-height: 500px;
   text-align: justify;

   p{
      text-align: justify
   }

   .status{
       padding: 15px 0;
   }

   .views{
		padding: 15px 0;
	}

   .publisher{
       padding: 15px 0;
   }

   .label{
	   font-size: 12px;
	   margin: 0;
	   font-weight: 500;
   }

   hr{
		border: 0px solid #fff;
		height: 2px;
		background-color: #ccc;
		border-radius: 2px;
	}

`;

const LoadingComponent = styled.div`

min-height: 500px;

`;


class Cover extends Component {
	state = {
		id: null,
		loading: true,
		hq: null,
	}

	componentWillReceiveProps(props) {
		const { idHQ } = props;

		const { id } = this.state;

		const {
			onLoadHQ, client,
		} = this.props;

		if (idHQ !== id) {
			this.setState({
				id: idHQ,
			}, async () => {
				const request = {
					query: getHqsById,
					variables: {
						id: idHQ,
					},
					errorPolicy: 'all',
				};
				const response = await api.get(client, request);
				this.setState({
					hq: response[0],
					loading: false,
				}, () => {
					const { hq } = this.state;

					onLoadHQ(hq);

					window.scrollTo(0, 0);
				});
			});
		}
	}


	render() {
		const { hq, loading } = this.state;

		if (loading) {
			return (<LoadingComponent><Loading on={loading} /></LoadingComponent>);
		}

		return (


			<Grid fluid>
				<Row middle="xs">
					<Col md={4}>
						<Figure
							imageUrl={hq.hqCover}
							alt={hq.name}
							width="90%"
						/>
					</Col>
					<Col md={8} sm={12}>
						<Synopsis>

							<section>
								<h1>
									{hq.name}
								</h1>
								<p>
									{hq.synopsis}
								</p>
							</section>

							<div className="publisher">
								<div className="label">
									{'Editora'}
								</div>
								<Link to={`/publisher/${hq.editoraId}/${url.urlFriendly(hq.publisherName)}`}>{hq.publisherName}</Link>
							</div>

							<div className="status">
								<div className="label">
									{'Status'}
								</div>
								{hq.status}
							</div>

							<div className="views">
								<div className="label">
									{'Visualizações'}
								</div>
								{HRNumbers.toHumanString(hq.impressionsCount)}
							</div>

							<MetaTags>
								<title>{`HQ Now - ${hq.name}`}</title>
								<meta name="description" content={hq.synopsis} />
								<meta property="og:title" content="HQ Now" />
								<meta property="og:image" content={hq.hqCover} />
							</MetaTags>

						</Synopsis>
					</Col>
				</Row>
			</Grid>


		);
	}
}

export default withApollo(Cover);
