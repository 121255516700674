import React, { Component } from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withApollo } from 'react-apollo';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import api from '../../../helpers/api';
import SearchIcon from '../../../assets/images/search.svg';

const SearchForm = styled.form`
  border: 1px solid #444;
  width: 100%;
  border-radius: 30px;
  overflow: hidden;
  transition: all .6s ease-in-out;
  padding-right: 7px;
  
  img{
	  width: 22px;
	  height: 22px;
  }

  button{
	border-radius: 3px;
  }

  img:hover{
	opacity: 0.6;
  }

  @media (max-width: 1280px){
		&{
			width: 90%;
			padding-right: 20px;
		}
	}

  @media (max-width: 767px){
		&{
			width: 85%;
			padding-right: 20px;
		}
	}
`;


class Search extends Component {
	state = {
		value: null,
		searchValid: false,
	}

	isNotValid= value => value === null || value === ''

	onChangeText = (value) => {
		this.setState({
			value,
			searchValid: this.isNotValid(value),
		});
	}

	onSearch = (event) => {
		event.preventDefault();
		const {
			onSearch, parameter, query, client,
		} = this.props;

		const { value } = this.state;

		if (!this.isNotValid(value)) {
			const request = {
				query,
				variables: {
					[parameter]: value,
				},
				errorPolicy: 'all',
			};
			const response = api.get(client, request);
			onSearch(response);
		}
	}

	render() {
		const { textPlaceholder } = this.props;

		const { searchValid } = this.state;

		return (
			<SearchForm>

				<Grid fluid>
					<Row middle="xs">
						<Col xs={11}>
							<TextField placeholder={textPlaceholder} onChange={this.onChangeText} />
						</Col>
						<Col xs={1}>
							<Button type="submit" disabled={searchValid} styleButton="light" onClick={this.onSearch}>
								<img src={SearchIcon} title="Buscar" alt="Buscar" />
							</Button>
						</Col>
					</Row>
				</Grid>


			</SearchForm>
		);
	}
}

Search.propTypes = {
	textPlaceholder: propTypes.string,
};

Search.defaultProps = {
	textPlaceholder: 'Buscar por...',
};

export default withApollo(Search);
