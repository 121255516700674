import React from 'react';
import styled from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from 'react-router-dom';
// import moment from 'moment';
import Figure from '../Figure';
import url from '../../../helpers/urlFriendly';

const ListFiguresComponent = styled.div`
	list-style: none;
	padding: 0;
    margin-bottom: 20px;

    > div{
        margin-left: -15px;
    }

    a{
        transition: all  0.4s ease-in-out;
		color: rgba(255, 255, 255, 0) !important;
		font-weight: 600;
		font-size: 1em;
    }

    a:hover{
        transition: all  0.4s ease-in-out;
        opacity: 0.4 !important;
		color: rgba(221, 35, 70, 1) !important;
		font-weight: 600;
		font-size: 1em;

		img{
			opacity: 0.1 !important;
		}
    }

	.updated{
		text-align: left;
		font-size: 14px;
		font-weight: 16;
	}

	section{
			text-align: left;
			margin-bottom: 60px;
	}

	@media (max-width: 767px){
		section{
			text-align: left;
			margin-top: -30px;
		}
	}

	.figure{
		margin-bottom: 10%;
		position: relative;

		.nameHQ{
			position: absolute;
			padding: 15px 3%;
			top: 0px;
			width: 94%;
		}
	}
	
	.link{
		color: rgba(221, 35, 70, 1) !important;
	}

	.link:hover{
		color: rgba(221, 35, 70, 1) !important;
	}

	p{
		font-size: 14px;
		text-align: justify;
	}

`;

// const formatDate = date => moment(date.substring(0, 10)).format('DD/MM/YYYY');


const ListFigures = ({
	data, name, id, loading, imageUrl, horizontal,
}) => (
	horizontal
		? (
			<ListFiguresComponent>
				<Grid fluid>

					{
						!loading && data && data.length !== 0
							? data.map(item => (
								<Row key={item[imageUrl]} className="row" center="xs" start="xs">
									<Col md={2} sm={12}>
										<Link to={`/hq/${item[id]}/${url.urlFriendly(item[name])}`}>
											<div className="figure">
												<Figure
													imageUrl={item[imageUrl]}
													alt={item[name]}
													width="96%"
												/>
												<div className="nameHQ">
													{item[name]}
												</div>
											</div>
										</Link>
									</Col>
									<Col md={10} sm={12}>
										<section>
											<h2 className="title">
												<Link className="link" to={`/hq/${item[id]}/${url.urlFriendly(item[name])}`}>
													{' '}
													{item[name]}
													{/* ' - ' */}
												</Link>


												{/* formatDate(item.updatedAt) */}

											</h2>
											<p>
												{
													item.synopsis
												}
											</p>
											<div className="updated">
												{' '}
												{'Capitulos Atualizados: '}

												<Link className="link" to={`/hq/${item[id]}/${url.urlFriendly(item[name])}`}>
													{
														item.updatedChapters
													}

												</Link>
												{' '}
											</div>
										</section>
									</Col>
								</Row>
							))
							: <Col xs={12}>Nenhum item encontrado.</Col>
					}

				</Grid>
			</ListFiguresComponent>
		)
		: (
			<ListFiguresComponent>
				<Grid fluid>
					<Row center="xs" start="xs">
						{
							!loading && data && data.length !== 0
								? data.map(item => (
									<Col key={item[imageUrl]} lg={2} md={4} sm={12}>
										<Link to={`/hq/${item[id]}/${url.urlFriendly(item[name])}`}>
											<div className="figure">
												<Figure
													imageUrl={item[imageUrl]}
													alt={item[name]}
													width="96%"
												/>
												<div className="nameHQ">
													{item[name]}
												</div>
											</div>
										</Link>
									</Col>
								))
								: <Col xs={12}>Nenhum item encontrado.</Col>
						}
					</Row>
				</Grid>
			</ListFiguresComponent>
		)
);


export default ListFigures;
