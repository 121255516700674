import 'normalize.css';
import React from 'react';

import ReactGA from 'react-ga';
import StyleComponent from './components/StyleComponent';
import Main from './main';
import routes from '../routes';
import MainHeader from './modules/MainHeader';
import Footer from './components/Footer';

const App = () => {
	const nameProject = 'HQ Now';
	ReactGA.initialize(process.env.REACT_APP_ANALYTICS);
	ReactGA.pageview(window.location.pathname + window.location.search);

	return (
		<StyleComponent>
			<MainHeader pages={routes} title={nameProject} />
			<Main routes={routes} />
			<Footer title={nameProject} pages={routes} />
		</StyleComponent>
	);
};

export default App;
