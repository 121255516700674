
import gql from 'graphql-tag';

export default gql`
query getCarouselOfHqs {
    getCarouselOfHqs{
        name
        hqId
        hqCover
    }
}`;
