import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import api from '../../../helpers/api';
import getHqsByPublisherId from '../../graphql/query/getHqsByPublisherId';

class Publisher extends Component {
	async componentDidMount() {
		const {
			publisherId, client, onFilterPublisher,
		} = this.props;

		const request = {
			query: getHqsByPublisherId,
			variables: {
				publisherId,
			},
			errorPolicy: 'all',
		};


		const response = api.get(client, request);

		window.scrollTo(0, 0);

		onFilterPublisher(response);
	}

	render() {
		const { name, loading } = this.props;

		return (
			loading ? <div />
				: (
					<h2 className="title title--list">
						{name}
					</h2>
				)
		);
	}
}


export default withApollo(Publisher);
