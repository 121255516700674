import React, { Component } from 'react';
import styled from 'styled-components';
import RenderHQ from '../../modules/RenderHQ';


const HomePage = styled.div`
	  min-height: 100vh;
	  padding-top: 20px;
	  padding-bottom: 20px;
`;


class Home extends Component {
	state = {
		loading: false,
	}

	changeChapter = () => {
		this.setState({
			loading: true,
		}, () => {
			this.setState({
				loading: false,
			});
		});
	}


	render() {
		const { loading } = this.state;
		if (loading) {
			return (<div />);
		}
		return (
			<HomePage>
				<RenderHQ changeChapter={() => this.changeChapter()} />
			</HomePage>
		);
	}
}


export default Home;
