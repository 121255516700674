
import gql from 'graphql-tag';

export default gql`
query getChapterById(
  $chapterId: Int!
) {
    getChapterById (
        chapterId: $chapterId
    ){
        name
        number
        oneshot
        pictures{
            pictureUrl
        }
        hq{
            id
            name
            capitulos{
                id
                number
            }
      }
  }
}`;
