const colors = {
	main: '#dd2346',
	alternative: '#0f99f2',
	text: '#333',
	textOut: '#FFF',
	header: '#FFF',
	background: '#F2F3F4',
	backgroundOut: '#22272f',
	backgroundFooter: '#31353d',
};


const header = {
	font: {
		size: '22px',
		color: colors.main,
		family: 'Arial',
	},
	background: {
		color: colors.header,
		height: '60px',
	},

};

const menu = {
	font: {
		size: '16px',
		color: colors.alternative,
		colorHover: colors.main,
		family: 'Arial',
	},
	background: {
		color: colors.header,
		mainColor: colors.background,
	},
};

const body = {
	font: {
		size: '16px',
		color: colors.text,
		family: 'Arial',
	},
};

const footer = {
	font: {
		size: '12px',
		color: colors.textOut,
		family: 'Arial',
	},
	background: {
		color: colors.backgroundOut,
		footerColor: colors.backgroundFooter,
	},
};

const banner = {
	text: {
		color: '#fff',
	},
	background: {
		color: '#bbb',
	},
};

export default {
	header,
	colors,
	body,
	menu,
	footer,
	banner,
};
