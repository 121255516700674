
import gql from 'graphql-tag';

export default gql`
query getHqsByFilters(
  $orderByViews: Boolean
  $limit: Int
  $publisherId: Int
  $loadCovers: Boolean
) {
    getHqsByFilters (
        orderByViews: $orderByViews
        limit: $limit
        publisherId: $publisherId
        loadCovers: $loadCovers
    ){
        id
        name 
        editoraId
        status
        publisherName
        hqCover
        synopsis
        updatedAt
  }
}`;
