
import gql from 'graphql-tag';

export default gql`
query getRecentlyUpdatedHqs {
    getRecentlyUpdatedHqs{
        name
        hqCover
        synopsis
        id
        updatedAt
        updatedChapters
    }
}`;
