
import type from './action-type';

const nextPreviousChapter = (hq, selected) => {
	if (hq.capitulos && hq.capitulos !== 0) {
		const index = hq.capitulos.map(chapter => chapter.id).indexOf(selected);

		return {
			index,
			nextChapter: hq.capitulos[index + 1] ? hq.capitulos[index + 1] : null,
			previousChapter: hq.capitulos[index - 1] ? hq.capitulos[index - 1] : null,
		};
	}


	return {
		nextChapter: null,
		previousChapter: null,
	};
};


const loadChapterHQ = (chapter, id) => async (dispatch) => {
	dispatch({
		type: type.LOAD_HQ_CHAPTERS,
		...chapter,
		numberPage: chapter && chapter.pictures ? chapter.pictures.length : 0,
		...nextPreviousChapter(chapter.hq, id),
	});
};

export default { loadChapterHQ };
