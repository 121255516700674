import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from 'react-apollo';
import store from './store';
import * as serviceWorker from './serviceWorker';
import App from './app';

const httpLink = new HttpLink({
	uri: process.env.REACT_APP_API,
	headers: { 'Access-Control-Allow-Origin': '*' },
	onError: (e) => { console.log(e.graphQLErrors); },
});


const authLink = setContext((_, { headers }) => ({
	headers: {
		...headers,
		'access-control-allow-origin': '*',
		'Access-Control-Allow-Origin': '*',
	},
}));


const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache({
		addTypename: false,
	}),
});

ReactDOM.render((
	<Provider store={store}>
		<BrowserRouter>
			<ApolloProvider client={client}>
				<App />
			</ApolloProvider>
		</BrowserRouter>
	</Provider>
), document.getElementById('root'));

serviceWorker.unregister();
