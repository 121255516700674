import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
// import Disqus from 'disqus-react';
import Cover from '../../containers/Cover';
import Table from '../../components/Table';
import actions from './store/actions';
import url from '../../../helpers/urlFriendly';

const CoverHQModule = styled.section`
  a{
	text-decoration: none;
	color: ${props => props.style.font.colorHover};
	transition: color 300ms ease-in-out;
  }

  a:hover{
	text-decoration: none;
	color: ${props => props.style.font.color};
  }
`;
class CoverHQ extends Component {
	state = {
		error: false,
		id: null,
	}


	componentDidMount() {
		const { id } = this.state;

		const { match } = this.props;

		if (match && id === null) {
			const { params } = match;

			this.setState({
				id: parseInt(params.id, 10),
			});
		}
	}

	formatChapters = ({ capitulos, name }) => {
		capitulos.sort((a, b) => (parseFloat(a.number) < parseFloat(b.number) ? 1 : -1));

		return capitulos && capitulos.length
			? capitulos.map(chapter => ({
				name:
				<Link to={`/hq-reader/${chapter.id}/${url.urlFriendly(name)}/chapter/${chapter.number}/page/1`}>
					{chapter.name ? chapter.name : `#${chapter.number}`}
				</Link>,
				id: chapter.id,
			})) : [];
	}

	onLoadHQ = async (hq) => {
		const { dispatch } = this.props;
		dispatch(actions.loadHQ(hq));
	}


	componentDidCatch(error, errorInfo) {
		console.log('error: ', error);
		console.log('errorInfo: ', errorInfo);
		this.setState({
			error: true,
		});
	}


	render() {
		const {
			error, id,
		} = this.state;

		const {
			hq,
			theme,
			// location,
		} = this.props;

		// const disqusShortName = process.env.REACT_APP_DISQUS_SHORTNAME;
		// const disqusConfig = {
		// 	url: window.location.href,
		// 	identifier: location.pathname,
		// 	title: hq.name,
		// };

		if (error) {
			return (<div> Ocorreu um Erro. Tente atualizar a página, por favor. </div>);
		}

		return (

			<CoverHQModule style={theme.menu}>
				<Grid fluid>
					<Row middle="xs">
						<Col xs={12}>
							<Cover
								idHQ={id}
								onLoadHQ={this.onLoadHQ}
							/>
						</Col>
					</Row>
					<Grid fluid>
						<Row middle="xs">
							<Col xs={12}>
								<h3 className="title">
									{'Capítulos'}
								</h3>
								<Table
									data={hq.id === id ? this.formatChapters(hq) : []}
									header={
										[{
											id: 'name',
											text: 'Nome',
										}]}
									id="id"
								/>
							</Col>
						</Row>
					</Grid>
				</Grid>
				{/* <Grid>
					<Row middle="xs">
						<Col xs={12}>
							<Disqus.DiscussionEmbed shortname={disqusShortName} config={disqusConfig} />
							<FacebookProvider appId="617247364982234">
								<Page href="https://www.facebook.com/HqNow-250284552546512" tabs="timeline" />
							</FacebookProvider>
						</Col>
					</Row>
				</Grid> */}
			</CoverHQModule>


		);
	}
}

const mapStateToProps = state => ({
	hq: state.hq,
});


export default connect(mapStateToProps)(withRouter(withTheme(CoverHQ)));
