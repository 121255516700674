

const get = (client, request) => client.query({
	query: request.query,
	variables: request.variables,
	errorPolicy: request.errorPolicy,
	fetchPolicy: request.fetchPolicy,
})
	.then((response) => {
		const key = Object.keys(response.data);

		return response.data[key];
	})
	.catch(error => error);


export default { get };
