import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import HRNumbers from 'human-readable-numbers';
import FilterLetter from '../../containers/FilterLetter';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import actions from './store/actions';
import url from '../../../helpers/urlFriendly';
import getHqsByName from '../../graphql/query/getHqsByName';


const ListHQModule = styled.section`
  a{
	text-decoration: none;
	color: ${props => props.style.font.colorHover};
	transition: color 300ms ease-in-out;
  }

  a:hover{
	text-decoration: none;
	color: ${props => props.style.font.color};
  }
`;


const Section = styled.section`
	padding: 20px 0px;
`;

class ListHQ extends Component {
	state = {
		error: false,
	}


	onFilterLetter = async (responseSearch) => {
		const { dispatch, history } = this.props;
		dispatch(actions.searchListHQ(responseSearch));
		history.push('/search');
	}

	formatListHQ = hqs => (hqs && hqs.length ? hqs.map(item => ({
		title: <Link to={`/hq/${item.id}/${url.urlFriendly(item.name)}`}>{item.name}</Link>,
		publisher: <Link to={`/publisher/${item.editoraId}/${url.urlFriendly(item.publisherName)}`}>{item.publisherName}</Link>,
		status: item.status,
		id: item.id,
		impressionsCount: HRNumbers.toHumanString(item.impressionsCount),
	})) : [])


	componentDidMount = () => {
		document.top = 0;
	}

	componentDidCatch() {
		this.setState({
			error: true,
		});
	}


	render() {
		const { error } = this.state;

		const { hqs, theme } = this.props;

		if (error) {
			return (<div> Ocorreu um Erro </div>);
		}

		return (
			<ListHQModule style={theme.menu}>
				<Grid fluid>
					<Row middle="xs">
						<Col xs={12}>
							<FilterLetter
								onFilterLetter={this.onFilterLetter}
								query={getHqsByName}
								hqs={hqs}
							/>
						</Col>
					</Row>
					<Row middle="xs">
						<h2 className="title title--list">
							{'Busca'}
						</h2>
					</Row>
					<Row>
						<Col xs={12}>
							<Section>
								<Loading styleLoading={{ paddingTop: '250px' }} on={hqs.loading}>
									<Table
										data={this.formatListHQ(hqs.list)}
										header={
											[{
												id: 'name',
												text: 'Nome',
											},
											{
												id: 'publisher',
												text: 'Editora',
											},
											{
												id: 'status',
												text: 'Status',
											}, {
												id: 'impressionsCount',
												text: 'Visualizações',
											}]}
										id="id"
									/>
								</Loading>
							</Section>
						</Col>
					</Row>
				</Grid>
			</ListHQModule>
		);
	}
}

const mapStateToProps = state => ({
	hqs: state.hqs,
});

ListHQ.propTypes = {
	dispatch: propTypes.func.isRequired,
};

export default connect(mapStateToProps)(withRouter(withTheme(ListHQ)));
