import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { FacebookProvider, Like } from 'react-facebook';
import actions from './store/actions';
import Pagination from '../../containers/Pagination';
import ChangeChapter from '../../containers/ChangeChapter';
import Render from '../../components/Render';


const CoverHQModule = styled.section`

  padding: 0 2%;

  a{
	text-decoration: none;
	color: ${props => props.style.font.colorHover};
	transition: color 300ms ease-in-out;
  }

  a:hover{
	text-decoration: none;
	color: ${props => props.style.font.color};
  }
  
`;

class CoverHQ extends Component {
	state = {
		error: false,
		id: null,
		number: null,
		page: null,
		picturesLoaded: [],
		hqLoaded: null,
	}


	componentDidMount() {
		this.preRenderHQ();
	}

	componentWillReceiveProps() {
		this.preRenderHQ();
	}

	preRenderHQ = () => {
		const { id } = this.state;

		const { match } = this.props;

		document.top = 0;

		if (match && id === null) {
			const { params } = match;

			this.setState({
				id: parseInt(params.id, 10),
				number: parseInt(params.number, 10),
				page: parseInt(params.pageNumber, 10),
			});
		}
	}


	onLoadChapter = async (chapter) => {
		const { dispatch } = this.props;
		const { id } = this.state;
		dispatch(actions.loadChapterHQ(chapter, id));

		const loadingChapter = await chapter;

		this.setState({
			picturesLoaded: loadingChapter,
			hqLoaded: loadingChapter.hq,
		});
	}

	onChangeChapter = ({ number, id }) => {
		const { history, match, changeChapter } = this.props;
		const { title } = match.params;
		history.push(`/hq-reader/${id}/${title}/chapter/${number}/page/1`);

		this.preRenderHQ();

		changeChapter();
	}


	componentDidCatch() {
		this.setState({
			error: true,
		});
	}


	render() {
		const {
			error,
			id,
			number,
			page,
			picturesLoaded,
			hqLoaded,
		} = this.state;

		const {
			theme,
			hqChapter,
		} = this.props;


		if (error) {
			return (<div> Ocorreu um Erro </div>);
		}

		return (

			<CoverHQModule style={theme.menu}>

				<Pagination
					chapterId={id}
					onLoadChapter={this.onLoadChapter}
					chapter={number}
					page={page}
					hq={hqLoaded}
					pictures={picturesLoaded.pictures
						? picturesLoaded.pictures : []}
				>
					<Render />
				</Pagination>

				<ChangeChapter
					nextChapter={hqChapter && hqChapter.nextChapter}
					previousChapter={hqChapter && hqChapter.previousChapter}
					onChangeChapter={this.onChangeChapter}
				/>

				<center>
					<FacebookProvider appId="617247364982234">
						<Like href="https://www.facebook.com/HqNow-250284552546512" colorScheme="dark" showFaces share />
					</FacebookProvider>
				</center>
			</CoverHQModule>

		);
	}
}

const mapStateToProps = state => ({
	hqChapter: state.hqChapter,
});


export default connect(mapStateToProps)(withRouter(withTheme(CoverHQ)));
