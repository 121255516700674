import React from 'react';
import propTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import LoadingIcon from '../../../assets/images/loading.svg';

const LoadingComponent = styled.div`
	.loading{
		text-align: center;
		padding: 10px 0;

		div img{
			max-width: 80px
		}
	}

	.text{
		color: ${props => (props.theme.text)};
	}
`;

const Loading = ({
	children, on, theme, styleLoading,
}) => (
	<LoadingComponent theme={theme.colors}>
		{
			on ? (
				<div style={styleLoading} className="loading">
					<div><img src={LoadingIcon} alt="loading" /></div>
					<div className="text">Carregando</div>
				</div>
			)
				: (

					<React.Fragment>{ children }</React.Fragment>

				)
		}


	</LoadingComponent>
);

Loading.propTypes = {
	on: propTypes.bool.isRequired,
};


export default withTheme(Loading);
