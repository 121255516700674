import React from 'react';
import propTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';

const FooterMain = styled.footer`
	width: 100%;
	position: relative;
	z-index: 10;
	margin-top: 10px;
	background-color: ${props => props.style.background.footerColor};
	color: #fff;

	.logo{
		max-width: 120px;
		width: 100%;
		padding-top: 50px;
	}

	h5{
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 0px;
		padding-left: 12px;
		border-left: #ccc 2px solid;
	}

	ul{
		padding: 0px;
	}

	ul li{
		list-style: none;
		padding: 10px 0;
		text-align: left;
	}

	.link{
		text-decoration: none;
		padding: 4px 0;
		color: ${props => props.style.font.color};
		transition: opacity 300ms ease-in-out;
		opacity: 0.6;
		font-size: 14px;
	}

	.link:hover{
		text-decoration: none;
		opacity: 1;
		transition: opacity 300ms ease-in-out;
	}
	
`;

const FooterBlock = styled.div`

	width: 100%;
	background-color: ${props => props.style.background.color};
	position: relative;
	z-index: 10;
	
	.text{
		font-size: ${props => props.style.font.size};
		color: ${props => props.style.font.color};
		font-family: ${props => props.style.font.family};
		font-weight: 500;
	}

`;

const Footer = ({ title, theme }) => {
	const currentYear = new Date().getFullYear();


	return (
		<FooterMain style={theme.footer}>

			{
				/*

			<Grid fluid>
				<Row center="xs">
					<Col xs={12} style={{ maxWidth: 1280 }}>
						<Grid fluid>
							<Row start="xs">
								<Col md={4} sm={12}>
									<h5>Menu</h5>
									<ul>
										{
											pages.map((page) => {
												if (page.menu) {
													return (
														<li key={page.path}>
															<Link className="link" to={page.path}>{page.title}</Link>
														</li>
													);
												}
												return null;
											})
										}
									</ul>
								</Col>
								<Col md={4} sm={12} />
								<Col md={4} sm={12}>
									<Row center="xs">
										<Col md={4} sm={12}>
											<img src={FooterIcon} alt="HQ NOW" className="logo" />
										</Col>
									</Row>
								</Col>
							</Row>
						</Grid>
					</Col>
				</Row>
			</Grid>
	*/
			}

			<FooterBlock style={theme.footer}>
				<Grid fluid>
					<Row center="xs">
						<Col xs={12}>
							<h4 className="text">{`${title} - ${currentYear}`}</h4>
						</Col>
					</Row>
				</Grid>
			</FooterBlock>

		</FooterMain>
	);
};

Footer.propTypes = {
	title: propTypes.string,
};

Footer.defaultProps = {
	title: 'Boilerplate',
};

export default withTheme(Footer);
