import React from 'react';
import styled, { ThemeProvider, injectGlobal } from 'styled-components';
import theme from '../../theme';


ThemeProvider.defaultProps = {
	theme: {},
};

const StyleDiv = styled.div`
    color: ${props => props.style.font.color};
    font-family: ${props => props.style.font.family};
	font-size: ${props => props.style.font.size};
`;

/* eslint-disable-next-line */
injectGlobal`
  	body{
		background-color: ${theme.colors.background}
	}

	hr{
		border: 0 solid #fff;
		height: 1px;
		background-color: #ccc;
		margin: 30px 0px;
	}

	.title{
		text-align: left;
		border-left: ${theme.colors.main} 2px solid;
		padding-left: 1%;
		font-size: 16px;
		position: relative;
		z-index: 2;

		&--center{
			display: inline-block;
			text-align: center;
		}

		
		&--banner{
			font-size: 30px;
			border-left: ${theme.colors.alternative} 2px solid;
			margin: 5px 5px;
			padding: 5px 0;
			padding-left: 1%;
		}

		&--list{
			margin: 40px 0 0 10px;
		}
	}

	
`;


const StyleComponent = ({ children }) => (
	<ThemeProvider theme={theme}>
		<StyleDiv style={theme.body}>
			{children}
		</StyleDiv>
	</ThemeProvider>
);


export default StyleComponent;
