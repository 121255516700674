
import gql from 'graphql-tag';

export default gql`
query getHqsByName(
  $name: String!
) {
    getHqsByName (
     name: $name
    ){
        id
        name 
        editoraId
        status
        publisherName
        impressionsCount
  }
}`;
