import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import Carousel from '../../components/Carousel';
import BackgroundImage from '../../../assets/images/background.jpg';
import MostView from '../../modules/MostView';
import LastUpdated from '../../modules/LastUpdated';
import Highlights from '../../modules/Highlights';

const HomePage = styled.div`
	  padding-top: 20px;
	  padding-bottom: 20px;

	  .text{
		  color: #fff;
		  text-align: left;
		  font-size: 16px;
		  margin: 10px 0;
		  position: relative;
		  z-index: 1;
	  }
`;


const Home = () => (
	<HomePage>
		<MetaTags>
			<title>HQ Now - Leitor De Quadrinhos Online</title>
			<meta name="description" content="HQ Now - Leitor De Quadrinhos Online - Online Comic Reader Batman Superman homem aranha vingadores" />
			<meta id="meta-description" name="description" content="HQ Now - Leitor De Quadrinhos Online - Online Comic Reader Batman Superman homem aranha vingadores." />
			<meta property="og:title" content="HQ Now - Leitor De Quadrinhos Online" />
		</MetaTags>

		<Carousel page={
			{
				title: 'Bem-vindo ao Hq-Now!',
				text: 'Leitor de Quadrinhos Online',
				backgroundImage: BackgroundImage,
			}}
		/>

		<Highlights limit={2} />

		<Grid fluid>
			<Row center="xs">
				<Col xs={12} style={{ maxWidth: 1280 }}>
					<LastUpdated limit={12} pageMostView />
					<MostView limit={6} pageMostView />
				</Col>
			</Row>
		</Grid>
	</HomePage>
);

export default Home;
