
import gql from 'graphql-tag';

export default gql`
query getHqsById(
  $id: Int!
) {
    getHqsById (
        id: $id
    ){
        id
        name
        synopsis
        editoraId
        status
        publisherName
        hqCover
        impressionsCount
        capitulos{
          name
          id
          number
        }
  }
}`;
