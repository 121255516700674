import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import CoverHQ from '../../modules/CoverHQ';

const HQPage = styled.div`
	  min-height: 100vh;
	  padding-top: 20px;
	  padding-bottom: 20px;
`;

const HQ = () => (


	<HQPage>
		<Grid fluid>
			<Row center="xs">
				<Col xs={12} style={{ maxWidth: 1280 }}>
					<CoverHQ />
				</Col>
			</Row>
		</Grid>
	</HQPage>

);


export default HQ;
