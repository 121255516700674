import React, { Component } from 'react';

import styled, { withTheme } from 'styled-components';
import { Grid, Row, Col } from 'react-flexbox-grid';

const MainCarousel = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 300px;
	margin-top: -14px;
	max-height: 468px;
	.text{
		padding-left: 5px;
	}
`;

const Main = styled.div`
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 300px;
    max-height: 468px;
`;


const CarouselBlock = styled.div`
	border-top: 1px solid #ddd;
	background-color: ${props => props.style.background.color};
	width: 100%;
	height: 100%;
    background-image: url(' ${props => props.image}');
    background-position: 0px 0px;
    background-size: 100vw;
    background-repeat: no-repeat;
    color:  #fff;
    font-size: 30px;
    background-attachment: fixed;
	transition: all  0.4s ease-in-out;
	position: absolute;
	padding-top: 50px;

	.main{
		top: 0;
		transition: all  0.4s ease-in-out;
	}

    @media (max-width: 1720px){
        &{
            background-attachment: inherit;
            background-size: cover;
        }
    }

    
`;


class Carousel extends Component {
	state = {
		element: null,
	}

	componentDidMount = () => {
		this.setState({
			element: this.mountCarousel(),
		});
	}


	mountCarousel() {
		const { page, theme } = this.props;


		const carousel = (
			<Main>
				<CarouselBlock
					style={theme.banner}
					image={page.backgroundImage}
				>
					<div className="main">
						<Grid fluid>
							<Row center="xs" middle="xs">
								<Col xs={12} style={{ maxWidth: 1280 }}>
									<h1 className="title title--banner">{page.title}</h1>
									<p className="text">
										{page.text}
									</p>
								</Col>
							</Row>
						</Grid>
					</div>
				</CarouselBlock>
			</Main>
		);

		return carousel;
	}


	render() {
		const { element } = this.state;

		return (

			<MainCarousel>
				{ element }
			</MainCarousel>


		);
	}
}

export default withTheme(Carousel);
