import React, { Component } from 'react';
import styled from 'styled-components';
import LoadingIcon from '../../../assets/images/loading.svg';

const FigureComponent = styled.figure`

	width: ${props => (props.width ? props.width : 'auto')};
	margin: 0;

	.loading{
		text-align: center;
		padding: 10px 0;
		max-width: 80px;
	}

	img{
		width: ${props => (props.width ? props.width : '100%')};
		max-height: ${props => (props.sizeHeight && !props.fullScreen ? '80vh' : 'auto')};
		height: ${props => (props.fullScreen ? '100vh' : 'auto')};
		display: inline-block;
	}

	@media(max-width: 1280px){
		img{
			width: 100%;
			height: auto;
		}
	}

	.hidden{
		opacity: 0;
		transition: all  0.2s ease-in-out;
		position: fixed;
	}

	.visible{
		opacity: 1;
		transition: all  0.2s ease-in-out;
		padding:  ${props => (props.fullScreen ? '0px' : '5px')};
		border: solid 1px #ccc;
		background-color:  ${props => (props.fullScreen ? '#000' : '#fff')};
	}
`;

class Figure extends Component {
	state = {
		loading: true,
		error: false,
	}

	handleImageLoaded = () => {
		this.setState({ loading: false });
	}

	handleImageErrored = () => {
		this.setState({ error: true });
	}


	render() {
		const {
			imageUrl,
			alt,
			sizeHeight,
			fullScreen,
			width = 'auto',
		} = this.props;

		const {
			error,
			loading,
		} = this.state;


		return (
			<FigureComponent sizeHeight={sizeHeight} fullScreen={fullScreen} width={width}>
				<img
					src={imageUrl}
					onLoad={this.handleImageLoaded}
					onError={this.handleImageErrored}
					alt={alt}
					className={loading ? 'hidden' : 'visible'}
				/>
				{
					error ? 'Ocorreu um erro' : <div />
				}
				{
					loading ? <img src={LoadingIcon} alt="loading" style={{ maxWidth: '120px' }} /> : <div />
				}

			</FigureComponent>
		);
	}
}


export default Figure;
