import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import styled from 'styled-components';
import MetaTags from 'react-meta-tags';
import MostView from '../../modules/MostView';

const MostViewPage = styled.div`
	  padding-top: 20px;
	  padding-bottom: 20px;
    min-height: 100vh;

	  .text{
		  color: #fff;
		  text-align: left;
		  font-size: 16px;
		  margin: 10px 0;
	  }
`;


const Home = () => (
	<MostViewPage>
		<MetaTags>
			<title>HQ Now - Mais Vistos</title>
			<meta name="description" content="HQ Now" />
			<meta property="og:title" content="HQ Now" />
		</MetaTags>

		<Grid fluid>
			<Row center="xs">
				<Col xs={12} style={{ maxWidth: 1280 }}>
					<MostView limit={300} />
				</Col>
			</Row>
		</Grid>
	</MostViewPage>
);

export default Home;
