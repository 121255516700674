import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import actions from './store/actions';
import Highlights from '../../containers/Highlights';
import getHqsByFilters from '../../graphql/query/getCarouselOfHqs';

const HighlightsModule = styled.section`
  min-height: 200px; 
  background-color: ${props => props.style.background.mainColor};
  margin: auto;
  margin-top: -120px;
  z-index: 2;
  position: relative;
  max-width: 1300px;

  a{
	text-decoration: none;
	color: ${props => props.style.font.colorHover};
	transition: color 300ms ease-in-out;
  }

  a:hover{
	text-decoration: none;
	color: ${props => props.style.font.color};
  }
  
`;

class CoverHQ extends Component {
	state = {
		error: false,
		id: null,
	}

	componentDidMount() {
		const { id } = this.state;

		const { match } = this.props;

		document.top = 0;

		if (match && id === null) {
			const { params } = match;

			this.setState({
				id: parseInt(params.id, 10),
			});
		}
	}


	onLoad = async (chapter) => {
		const { dispatch } = this.props;
		dispatch(actions.highlights(chapter));
	}

	componentDidCatch() {
		this.setState({
			error: true,
		});
	}

	render() {
		const {
			error,
		} = this.state;

		const {
			theme,
			highlights,
			limit,
		} = this.props;


		const {
			loading,
			list,
		} = highlights;

		if (error) {
			return (<div> Ocorreu um Erro </div>);
		}


		return (
			<HighlightsModule style={theme.menu}>
				<Highlights
					query={getHqsByFilters}
					variables={{
						orderByViews: true,
						loadCovers: true,
						limit,
					}}
					data={list}
					onLoad={this.onLoad}
					loading={loading}
				/>
			</HighlightsModule>

		);
	}
}

const mapStateToProps = state => ({
	highlights: state.highlights,
});


export default connect(mapStateToProps)(withRouter(withTheme(CoverHQ)));
