import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router';
import styled, { withTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import Publisher from '../../containers/Publisher';
import Table from '../../components/Table';
import Loading from '../../components/Loading';
import actions from './store/actions';
import url from '../../../helpers/urlFriendly';

const PublisherModule = styled.section`
  a{
	text-decoration: none;
	color: ${props => props.style.font.colorHover};
	transition: color 300ms ease-in-out;
  }

  a:hover{
	text-decoration: none;
	color: ${props => props.style.font.color};
  }
`;


const Section = styled.section`
	padding: 20px 0px;
`;

class ListPublisher extends Component {
	state = {
		error: false,
		id: null,
		loadingId: true,
	}


	onFilterPublisher = async (responseSearch) => {
		const { dispatch } = this.props;
		dispatch(actions.searchPublisher(responseSearch));
	}

	formatPublisher = hqs => (hqs && hqs.length ? hqs.map(item => ({
		title: <Link to={`/hq/${item.id}/${url.urlFriendly(item.name)}`}>{item.name}</Link>,
		publisher: <Link to={`/publisher/${item.editoraId}/${url.urlFriendly(item.publisherName)}`}>{item.publisherName}</Link>,
		status: item.status,
		id: item.id,
	})) : [])


	componentDidMount = () => {
		const { id } = this.state;

		const { match } = this.props;


		if (match && id === null) {
			const { params } = match;

			this.setState({
				id: parseInt(params.id, 10),
				loadingId: false,
			});
		}
	}

	componentDidCatch() {
		this.setState({
			error: true,
		});
	}


	render() {
		const { error, id: publisherId, loadingId } = this.state;

		const { theme, publisher } = this.props;

		const { list, loading, name } = publisher;


		if (error) {
			return (<div> Ocorreu um Erro </div>);
		}

		if (loadingId) {
			return (<div />);
		}

		return (
			<PublisherModule style={theme.menu}>
				<Grid fluid>
					<Row middle="xs">
						<Col xs={12}>
							<Publisher
								onFilterPublisher={this.onFilterPublisher}
								publisherId={publisherId}
								loading={loadingId}
								name={name}
							/>
						</Col>
					</Row>
					<Row>
						<Col xs={12}>
							<Section>
								<Loading styleLoading={{ paddingTop: '250px' }} on={loading}>
									<Table
										data={this.formatPublisher(list)}
										header={
											[{
												id: 'name',
												text: 'Nome',
											},
											{
												id: 'publisher',
												text: 'Editora',
											},
											{
												id: 'status',
												text: 'Status',
											}]}
										id="id"
									/>
								</Loading>
							</Section>
						</Col>
					</Row>
				</Grid>
			</PublisherModule>
		);
	}
}

const mapStateToProps = state => ({
	publisher: state.publisher,
});

export default connect(mapStateToProps)(withRouter(withTheme(ListPublisher)));
